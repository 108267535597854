.main-Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10;
}

.legends-container {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  /* border: 1px solid red; */
}

.legendPoints {
  margin-right: 10px;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  /* border: 1px solid green; */
  /* display: flex; */
  /* align-items: center;
  justify-content: center;
  border: 1px solid red; */
}
.syllabusYearDuration {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding-bottom: 10px;
}
.syllabusYears {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.legendContainer {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}
.leftSideBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 1px solid red; */
  padding: 20px 0px;
}

.legendsColorBox {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 2;
  margin-right: 5px;
}
.typeSelectOptionContainer {
  display: flex;
  margin-top: 10px;
  gap: 20px;
}
.modalLegendOptions {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px !important;
}

.modalLegendOptionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  font-size: 1rem;
}
.modalLegendOptionsContainer :hover {
  border: 1px solid black !important;
}
/* .modalLegendOptions :hover {
 border:1px solid gray !important
 
} */
.modalLegendOptionsMain {
  display: flex;
  flex-direction: column !important;
  gap: 10px;
}
.calendar-madal-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
}

.event-box {
  align-items: center;
  display: flex;
  justify-content: center;
  flex: 1;
  position: relative; /* Make sure it has a position */
}

.event_day_name {
  color: #000;
  font-size: 16px !important;
}
.date-cell-render-main {
  display: flex !important;
  height: inherit !important;
  flex-direction: column !important;
}

/* .date-cell-render-main :hover {
  opacity: 1;
} */
.event-box:hover ::before {
  opacity: 1;
}
.event-box :before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.4); */
  transition: 0.5s;
  opacity: 0;
  z-index: 2;
}

.event-box:hover .onHover {
  opacity: 1;
  transform: translateY(0px);
}
.onHover-buttons {
  width: 20%;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}
.event-box .onHover {
  position: absolute;
  top: 1;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 3;
  color: white;
  opacity: 0;
  transform: translateY(30px);
  transition: 0.5s;
  display: flex;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
}

.ant-badge.ant-badge-status .ant-badge-status-success {
  height: 15px !important;
  width: 15px !important;
}
/* .ant-picker-cell-inner .ant-picker-calendar-date{
background-color: blue !important;
} */
