.head_learning {
  font-size: 30px;
  font-family: "Plus Jakarta Sans";
  font-weight: 500;
  margin-bottom: 13px;
}

.learning_text {
  color: #667085;
  font-size: 16px;
  font-weight: 400;
  font-family: "Plus Jakarta Sans";
}

.leaning_parent {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 30px;
  overflow-y: scroll;
  overflow-x: none;
  scrollbar-width: none;
}

.btn_leaning {
  padding: 8px 20px;
  color: black;
  border-radius: 5px;
  font-size: 17px;
  cursor: pointer;
}

.btn_leaning_active {
  background-color: var(--secondary-color);
  color: white;
}

.learing_Child {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 600;
  font-family: "Plus Jakarta Sans";
}

.leaning_card {
  display: flex;
  /* align-items: center; */
  gap: 24px;
  /* flex-wrap: wrap; */
  margin-top: 30px;

  align-items: stretch;

  height: 112px;
}

.learning_Card_child {
  flex: 1;
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #eaecf0;
  /* flex-wrap: wrap; */

  /* width: calc((100% - 48px) / 3); */

  height: 100%;

  /* flex-basis: min-content; */
}

@media (max-width: 1650px) {
  .leaning_card {
    height: 180px !important;
  }
  .card_learning {
    flex-direction: column !important;
    align-items: initial !important;
  }
}

@media (max-width: 1439px) {
  .leaning_card {
    height: initial !important;
    flex-direction: column;
  }
  .card_learning {
    flex-direction: row !important;
    align-items: center !important;
  }
}

@media (max-width: 550px) {
  .card_learning {
    flex-direction: column !important;
    align-items: initial !important;
  }
}

.pro_leaning {
  color: #bde556;
  /* background-color: #26252A; */
}

.card_learning {
  display: flex;
  align-items: center;
  gap: 24px;
  /* flex-wrap: wrap; */
}

.card_learning_text {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
}

.card_learning .card_btn {
  background-color: #26252a;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}

.card_learning_text h1 {
  font-size: 30px;
  font-weight: 600;
  font-family: "Plus Jakarta Sans";
  margin: 0;
  line-height: 38px;
}

.card_learning_text p {
  font-size: 16px;
  color: #26252a;
  font-weight: 500;
  margin: 0;
  line-height: 24px;
}

.card_learning_btn {
  background-color: #26252a;
  color: white;
  padding: 10px 20px;
  border-radius: 7px;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
}

.learning_tab {
  margin-top: 20px;
}

.custom-column-group-title {
  font-size: 16px;
  font-weight: 600;
}

.tab_learning_header {
  border-radius: 8px;
  width: 100%;
  border: 1px solid #eaecf0;
  margin-top: 20px;
}

.tab_learning_text {
  text-align: center;
  padding: 20px 0;
  background-color: #eff8ff !important;
  color: black !important;
  font-weight: 600;
  border-radius: 5px;
  font-size: 16px;
}

.learning_tab {
  padding: 14px 0px;
}

.learning_tab {
  .ant-table-thead > tr > th {
    /* background-color: var(--secondary) !important; */
    color: black !important;
  }
}

.learning_btn_tabs_parent {
  display: flex;
  gap: 8px;
  align-items: center;
  overflow-y: scroll;
  overflow-x: none;
  scrollbar-width: none;
}

.learn_btn_tabs_child {
  padding: 14px 28px;
  background-color: #f7f7f7;
  font-weight: 600;
  min-width: max-content;
  width: 120px;
  cursor: pointer;
  color: black;
  border-radius: 4px;
}

.learn_btn_tabs_child_active {
  padding: 14px 28px;
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

.level_learning_tab {
  padding: 14px;
  display: flex;
  gap: 2rem ;
  align-items: center;
  background-color: #f7f7f7;
  overflow-x: auto;
}

.level_learning_child {
  flex: 1;
  min-width: 400px;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.level_learning_child_btn {
  width: 100%;
  background-color: #fff;
  text-align: center;
  color: #26252a;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  font-weight: 500;
  padding: 10px 0;
}
.level_learning_child p {
  color: #26252a;
  font-size: 12px;
  font-weight: 600;
  font-family: "Plus Jakarta Sans";
}

.student_subject_level_loading_box {
  width: 100%;
  height: 100%;
  min-height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1100px) {
  .card_learning_text p {
    font-size: 13px;
  }
  .learning_Card_child {
    padding: 16px;
  }
  .head_learning {
    font-size: 28px;
  }
  .head_learning p {
    font-size: 17px;
  }
  .card_learning_text h1 {
    font-size: 28px;
  }
  .learning_text {
    font-size: 15px;
  }
}
@media only screen and (max-width: 800px) {
  .head_learning {
    font-size: 26px;
  }
  .head_learning p {
    font-size: 16px;
  }
  .card_learning_text h1 {
    font-size: 26px;
  }
  .btn_leaning {
    padding: 7px 18px;
    font-size: 16px;
  }
  .learning_text {
    font-size: 14px;
  }
}
@media only screen and (max-width: 650px) {
  .level_learning_tab {
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
  .level_learning_child {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .leaning_card {
    flex-direction: column;
  }
  .learning_Card_child {
    width: 100%;
  }
  .head_learning {
    font-size: 20px;
    margin-top: 20px;
  }
  .card_learning_text h1 {
    font-size: 23px;
  }
  .head_learning p {
    font-size: 15px;
  }
  .btn_leaning {
    padding: 6px 16px;
    font-size: 15spx;
  }
  .learning_text {
    font-size: 13px;
  }
}
@media only screen and (max-width: 430px) {
  .head_learning {
    font-size: 17px;
    text-align: center;
  }
  .head_learning p {
    font-size: 14px;
  }
  .card_learning_text h1 {
    font-size: 21px;
  }
  .btn_leaning {
    padding: 6px 16px;
    font-size: 15px;
  }
  .learing_Child {
    text-align: center;
  }
  .learning_text {
    font-size: 12px;
    text-align: center;
  }
}
@media only screen and (max-width: 400px) {
  .head_learning {
    font-size: 16px;
  }
  .head_learning p {
    font-size: 13px;
  }
  .card_learning_text h1 {
    font-size: 18px;
  }
  .btn_leaning {
    padding: 5px 10px;
    font-size: 15px;
  }
}
