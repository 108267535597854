.overviewHeading {
  font-size: 38px;
  font-weight: 400;
  margin-bottom: 0;
}

.reviewAndManage {
  font-size: 16px;
  color: #667085;

  line-height: 30px;
  font-weight: 400;
}

.studentBrief {
  font-size: 18px;
  font-weight: 600;
}

.icon-plus-btn {
  position: relative;
  top: 2px;
  margin-right: 5px;
}

.addCategory {
  background-color: #000000;
  color: #ffffff;
  margin-left: 12px;
}

.addCategory:hover {
  background-color: #000000 !important;
  color: #ffffff !important;
  border-color: #000000 !important;
}

.project-collapse {
  background-color: #fafbfd !important;
  font-size: 14px !important;
}

.content-one {
  font-size: 14px;
}

.contentTwoParent,
.contentThreeParent {
  margin-top: 10px;
}

.collapseEditBtn,
.collapseDeleteBtn {
  border: none;
}

.collapseEditBtn {
  background-color: #f7f7f7;
  padding: 5px;
  width: 30px;
}

.collapseDeleteBtn {
  color: #f70000;
  background-color: #fef3f2;
  padding: 5px;
  width: 30px;
}

.ant-collapse-header {
  font-size: 16px !important;
}

.ant-collapse-arrow {
  position: absolute;
  right: 20px;
}

.studentOverviewContainer {
  padding: 20px;
}

.iconOutline{
   position: relative;
  right: 10px;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  background-color:#eff8ff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.personOutlined,
.emojiLaughing,
.ioSearch,
.graphUpArrow {
  position: relative;
  /* right: 10px; */
  /* background-color: #eff8ff; */
  /* border-radius: 50px; */
  /* padding: 20px; */
  width: 30px;
  /* height: 30px; */
}

.second-content,
.third-content {
  margin-top: 8px;
}

.add-btn {
  position: absolute;
  right: 50px;
  top: 10px;
  font-size: 25px;
}

.addStdCat {
  background-color: #000000;
  color: #ffffff;
  font-weight: bold;
  padding: 10px 12px 10px 12px;
  border-radius: 10px;
  cursor: pointer;
  position: absolute;
  right: 0;
}

.addStdCat:hover {
  color: #ffffff !important;
}

.classesStd {
  padding: 20px;
  /* display: flex; */
  /* width: calc(100% - 396px); */
}

.classesStdModal {
  background-color: #ffffff;
  border-radius: 10px;
}

.ant-modal-close {
  color: #000000 !important;
}

.classStdIcon {
  font-size: 30px;
  color: #175cd3;
  background-color: #eff8ff;
  border-radius: 50px;
}

.classesStdInputOne {
  width: 100%;
  border: 1px solid #000000;
}

.add-message {
  width: 120px;
  background-color: #000000;
  color: #ffffff;
  margin-left: 10px;
}

.add-message:hover {
  color: #ffffff !important;
}

.classesStdCancel {
  width: 120px;
}

._breadcrumb {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  margin-bottom: 30px;
}
._breadcrumb span {
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}
._breadcrumb p {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background-color: var(--secondary-color);
  padding: 10px;
  border-radius: 7px;
}

/* Media query */

@media screen and (min-width: 1024px) {
  .addCategory {
    background-color: #000000;
    color: #ffffff;
    margin-left: 12px;
  }
}
