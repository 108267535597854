.yearly-calendar {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    /* padding: 20px; */
  }
  .legends-container {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    /* border: 1px solid red; */
  }
  
  .legendPoints {
    margin-right: 10px;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    /* border: 1px solid green; */
    /* display: flex; */
    /* align-items: center;
    justify-content: center;
    border: 1px solid red; */
  }
  .syllabusYearDuration {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding-bottom: 10px;
  }
  .syllabusYears {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .legendContainer {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
  }
  .leftSideBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border: 1px solid red; */
    padding: 20px 0px;
  }
  
  .legendsColorBox {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 2;
    margin-right: 5px;
  }
  .year-navigation{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
    background-color: var(--secondary-color);
    margin-bottom: 20px;
    border-radius: 10px;
    /* padding: 20px; */
    gap: 30px;
  }
  
  .yearChangeArrowButton{
    /* padding: 5px; */
    background-color:  var(--secondary-color);
    display: flex;
    margin-top: 5px;
  }
  .currentYear{
    font-size: 2.5rem;
    color: var(--primary-color);
  }
  .month {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    background: #fff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .month h2 {
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    font-weight: bold;
    text-align: center;
    margin-bottom: 5px;
  }
  
  .days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
  }
  
  /* .day {
    text-align: center;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;
  } */
  
  /* .day:hover {
    background: #007bff;
    color: white;
  } */
 
  .tooltip {
    position: absolute;
    top: -20px; /* Position the tooltip above the day */
    left: 50%;
    border:1px solid #000000;
    width: 20rem;
    transform: translateX(-20%);
    background-color:#ffffff;
    color:black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly; /* Space the buttons evenly */
    padding: 5px;
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); */
    z-index: 10;
    opacity: 0; /* Hide by default */
    transition: opacity 0.3s ease; /* Smooth transition for opacity */
  }
  
  .tooltip-button {
    background: transparent;
border: none;
    color: white;
    cursor: pointer;
    gap: 20px;

    font-size: 14px;
    padding: 5px 20px; 
    /* transition: background 0.3s ease; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
 
  .eventOnhover{
     padding: 10px 0px;
     width: fit-content;
     font-size: 1.2rem;
     /* border:1px solid red */
  }
  .onHover-button {
    box-sizing: border-box;
    /* width: 20rem; */
    border:  1px solid black;
    padding: 5px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: rgba(0, 0, 0, 0.4); */
    color: rgba(0, 0, 0, 0.4) !important;
  }
  .ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px; /* Adjust this width as per your design */
  cursor: pointer; /* Show cursor as pointer to indicate clickable text */
}
  .commentBox {
    box-sizing: border-box;
    /* width: 20rem; */
    /* color: #f0f0f0 !important; */
    /* border: 1px solid black; */
    margin-bottom: 5px;
    /* padding: 10px; */
    border-radius: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    /* background-color: #ffffff; */
  }
  
  /* .tooltip-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
  } */
  
  .day {
    position: relative; 
    text-align: center;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .day:hover {
    background: #f0f0f0;
    color: #007bff;
  }
  
  .day:hover .tooltip {
    opacity: 1;
  }
  