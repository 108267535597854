.std_header {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.std_main {
  display: flex;
  align-items: center;
  gap: 10px;
}

.std_p {
  font-size: 15px;
  font-weight: 600;
  font-family: "Plus Jakarta Sans";
}

.btn_planner {
  background-color: var(--secondary-color);
  color: white;
}

.btn_parent_planner {
  display: flex;
  gap: 5px;
  align-items: center;
}

.std_p span {
  font-size: 14px;
  font-weight: 400;
  font-family: "Plus Jakarta Sans";
}

.btn_planner {
  margin-top: 10px;
  background-color: var(--secondary-color);
  color: white;
  width: 30%;
}

.days {
  display: flex;
  align-items: center;
  gap: 10px;
}

.std_left {
  margin-left: 15px;
}

.std_width {
  width: 110px;
}

.std_child {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.custom_table_design .ant-table-tbody > tr > td {
  border-bottom: 1px solid #e8e8e8;
}

.std_disabled {
  /* pointer-events: none; */
  opacity: 0.3;
  /* cursor: not-allowed; */
}

.custom_table_design .ant-table-tbody > tr > td:last-child {
  border-right: none;
}

/* .custom_table_design .ant-table-thead>tr>th {
    background: #f5f5f5;
    border-bottom: 2px solid #e8e8e8;
} */

.custom_table_design .ant-table-thead > tr > th {
  background: var(--secondary) !important;
  color: #000 !important;
  border-bottom: 2px solid #e8e8e8;
}
.custom_table_design .tab_left {
  padding: 8px;
}

.custom_table_design .check_planner {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.std_span {
  display: flex;
  gap: 10px;
  align-items: center;
}

.std_dot {
  width: 8px;
  height: 8px;
  background-color: #bde556;
  border-radius: 50%;
}

.span_child {
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-weight: 400;
}

.main_Std_parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.planner_top {
  margin-top: 30px;
  font-family: "Plus Jakarta Sans";
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 30px;
}

.check_planner {
  display: flex;
  align-items: center;
  gap: 10px;
}

.subject-input {
  border: none;
  outline: none !important;
  padding: 10px 0;
  box-shadow: none !important;
}
.subject-input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.selct_plan_main {
  display: flex;
  justify-content: flex-end;
}

.selct_plan {
  width: 30%;
}

/* ////////////////////// planner details ///////////// */

.plan_details_select {
  width: 100%;
}
.plan_details_select_single {
  width: 40%;
}
.select_planner_parent {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 50%;
}
.select_planner_parent_single {
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
  gap: 10px;
  width: 50%;
}

.std_select {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 700px) {
  .main_Std_parent {
    justify-content: center;
    flex-direction: column;
  }

  .std_main {
    flex-direction: column;
  }
  .std_span {
    margin: auto;
  }
}

@media screen and (max-width: 660px) {
  .selct_plan_main {
    justify-content: center;
  }

  .planner_top {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .selct_plan {
    width: 50%;
  }

  .gheaderMain {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 500px) {
  .std_select {
    flex-direction: column;
    padding-bottom: 30px;
  }
  .select_planner_parent {
    flex-direction: column;
    width: 100%;
    gap: 20px;
  }
  .plan_details_select {
    width: 100%;
  }
}
